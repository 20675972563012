import React from 'react';
import { graphql } from 'gatsby';
import Path from '../services/path';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import { preparePodcastCards, preparePodcastListing } from '../services/podcast-helper';
import SlimlineBannerBlock from '@bfbs/storybook/dist/components/molecules/slimline-banner-block/slimline-banner-block';
import CardGroup from '@bfbs/storybook/dist/components/organisms/card-group/card-group';

const Podcasts = ({ data: { allFeedBfbsPodcastsItems } }) => {
  const breadcrumbs = [
    { name: 'Radio', path: Path.basePath },
    { name: 'Podcasts', path: null },
  ];

  const podcastItems = preparePodcastListing(allFeedBfbsPodcastsItems.nodes);
  const podcastCards = preparePodcastCards(podcastItems);

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <SEO title="Podcasts" />

      <SlimlineBannerBlock background="background--tertiary-two" heading="Podcasts & Specials" />

      <CardGroup customClasses="o-card-group--grid-4" isSlider={false} cards={podcastCards} />
    </Layout>
  );
};

export default Podcasts;

export const podcastQuery = graphql`
  query Podcasts {
    allFeedBfbsPodcastsItems(sort: { fields: isoDate, order: DESC }) {
      nodes {
        title
        content
        series
        new_episode
        itunes {
          image
          summary
        }
      }
    }
  }
`;
